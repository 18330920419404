import request from '@/utils/request'


export function GetUseSFExpressList(params) {
    return request({
        url: '/api/A3Express/GetUseSFExpressList',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },
    })
}

export function GetSFExpress(params) {
    return request({
        url: '/api/A3Express/GetSFExpress2',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },
    })
}
