<template>
    <div class="container" ref="wrapper">
        <van-sticky>
            <van-nav-bar title="顺丰未签收" left-arrow @click-left="$router.back()" />
        </van-sticky>

        <van-row>
            <van-col span="1"></van-col>
            <van-col span="8">
                <van-stepper :min="1" :max="30" v-model.number="page.filterData" @change="onSearch" />
            </van-col>
            <van-col span="5">
                <h3> 天未签收</h3>
            </van-col>

            <van-col span="4">
                <h3>共 {{ total }} 单</h3>
            </van-col>
        </van-row>


        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="upLoading" :finished="finished" @load="onLoad" finished-text="没有更多了">
                <van-cell-group>
                    <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
                    <van-cell v-for="item in articles" :key="item.transportID" @click="toArticleDetail(item.transportID)">
                        <van-row>
                            <van-col span="12">
                                <h3 style="display: inline-block">{{ item.memberName }}</h3>
                            </van-col>
                        </van-row>
                        <van-row>
                            <van-col span="12">
                                订单:{{ item.orderId }}
                            </van-col>
                            <van-col span="12">
                                已发货 {{ item.addDay }} 天
                            </van-col>

                        </van-row>
                        <van-row>
                            <van-col span="12">
                                快递:{{ item.transportID }}
                            </van-col>
                            <van-col span="12">
                                发货日期： {{ item.createTime.substring(0, 10) }}
                            </van-col>


                        </van-row>
                    </van-cell>
                </van-cell-group>
            </van-list>
        </van-pull-refresh>
    </div>
</template>
    
<script>
import { GetUseSFExpressList } from "@/api/A3Express";
//const key = "zn-history";
export default {
    name: "answerList",
    data() {
        return {
            scroll: 0,
            refreshing: false,
            keyword: "",
            upLoading: false,
            finished: false,
            total: 0,
            articles: [],
            page: {
                current: 1,
                pageSize: 10,
                sorterType: 0,
                filterData: 3,
            },
        };
    },
    components: {},
    activated() {
        //  window.scrollTo(0, this.scroll);
        //  alert( this.$refs.wrapper.scrollTop);
        try {
            this.$refs.wrapper.scrollTop = this.scroll;
        } catch (error) {
            console.log(error);
        }
    },
    created() {
        this.onSearch();
    },
    mounted() { },

    methods: {
        onRefresh() {
            this.onSearch();
            this.refreshing = false;
        },

        onSearch() {

            if (isNaN(this.page.filterData)) {
                return;
            }

            this.finished = false;
            this.articles = [];
            this.page.current = 1;
            this.page.pageSize = 10;
            this.onLoad();
        },
        toArticleDetail(id) {
            let wrapperScrollTop = this.$refs.wrapper.scrollTop;
            //存储 scrollTop 的值
            this.scroll = wrapperScrollTop;
            if (id.indexOf('SF') >= 0) {
                this.$router.push({
                    path: "/sfexpress/detail",
                    query: { para: id },
                });
            }
        },
        async onLoad() {
            this.upLoading = true;
            let aresult = await GetUseSFExpressList({ ...this.page }); //({ ...this.page })
            this.articles.push(...aresult.data.data);
            this.upLoading = false;
            this.total = aresult.data.total;
            if (this.page.current * this.page.pageSize < aresult.data.total) {
                this.page.current++;
            } else {
                this.finished = true;
            }
        },
    },
};
</script>
    
<style lang="less" scoped>
.w100 {
    /deep/ img {
        width: 100%;
        max-height: 80px;
    }
}

.zntitle {
    height: 80px;
}

.container {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}

.detail {
    padding: 46px 10px 44px;

    .title {
        font-size: 18px;
        line-height: 2;
    }

    .zan {
        text-align: center;
        padding: 10px 0;

        .active {
            border-color: red;
            color: red;
        }
    }

    .author {
        padding: 10px 0;
        display: flex;
        position: sticky;
        background-color: #fff;
        top: 46px;
        z-index: 2;

        .text {
            flex: 1;
            padding-left: 10px;
            line-height: 1.5;

            .name {
                font-size: 14px;
                margin: 0;
            }

            .time {
                margin: 0;
                font-size: 12px;
                color: #999;
            }
        }
    }

    .content {
        padding: 20px 0;
        overflow: hidden;
        white-space: pre-wrap;
        word-break: break-all;

        /deep/ img {
            max-width: 100%;
            background: #f9f9f9;
        }

        /deep/ code {
            white-space: pre-wrap;
        }
    }
}
</style>
    